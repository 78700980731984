import React from 'react';
import Splash from '../components/Splash';
import ProjectContainer from '../components/ProjectContainer';

const Home = () => {
  return (
    <>
      <Splash />
      <ProjectContainer />
    </>
  );
};

export default Home;
